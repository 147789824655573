<template>
  <div class="maintenance">
    <div class="tile">
      <vue-good-table :columns="columns" :rows="rows" :pagination-options="{
        enabled: true,
      }" :search-options="{
  enabled: true,
  placeholder: 'Search',
}">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'buttons'" class="d-grid d-md-flex justify-content-md-center">
            <button type="button" :class="['btn btn-toggle', props.row.status ? 'active' : '']" data-toggle="button"
              aria-pressed="false" autocomplete="off" @click="toggleMaintenance(props.row)">
              <div class="handle"></div>
            </button>
          </div>
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <div slot="emptystate">
          <span>No data</span>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mutations } from "../store";
export default {
  data() {
    return {
      columns: [
        {
          label: "Website",
          field: "website",
        },
        {
          label: "Mode",
          field: "buttons",
          hidden: false,
        },
      ],
      rows: [],
    };
  },
  created() {
    this.getMaintenance();
  },
  methods: {
    getMaintenance() {
      this.$axios({
        url: "/maintenance",
      })
        .then(({ data }) => {
          this.rows = data.maintenance;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleMaintenance(maintenance) {
      maintenance.status = !maintenance.status;
      this.$axios
        .patch(`maintenance/${maintenance._id}`, maintenance)
        .then(({ data: { success, message, updatedMaintenance } }) => {
          if (success) {
            let maintenanceIndex = this.rows.findIndex(({ _id }) => {
              return _id === updatedMaintenance._id;
            });
            if (maintenanceIndex > -1) {
              this.rows.splice(maintenanceIndex, 1, updatedMaintenance);
            }
          }
          window.scrollTo(0, 0);
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>